.modal_outside {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(67, 67, 67, 0.581);
  z-index: 1000;
  width: 100%;
  height: 100vh;
}

.modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: calc(100% - 100px);
  height: calc(100vh - 65px);
  overflow: scroll;
  padding: 20px;
  margin: 30px 50px;
  border-radius: 3px;
}

@media screen and (max-width: 760px) {
  .modal {
    top: 30px;
    left: 0;
    padding: 0;
    margin: 0;
    margin-bottom: 100px;
    padding: 15px 10px;
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: scroll;
    border-radius: 4px 4px 0 0;
  }
}
