.user_list {
  --columns: 3;
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  gap: 50px;
  list-style-type: none;
}

@media screen and (max-width: 1000px) {
  .user_list {
    --columns: 2;
  }
}

@media screen and (max-width: 620px) {
  .user_list {
    --columns: 1;
    gap: 50px;
  }
}

.about_user {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.user_item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-decoration: none;
  color: black;
  gap: 23px;
  position: relative;
}

.user_item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(27deg, #d2d2d2 -30%, rgba(248, 248, 248, 0) 75%);
  z-index: -1;
}

.preview_photos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.preview_img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  background: rgb(214, 214, 214);
}

.user_img {
  max-width: 60px;
  max-height: 60px;
  width: 60px;
  height: 60px;
  border: 1px solid #a8a8a8;
  border-radius: 100%;
  overflow: hidden;
}

.user_img > img {
  height: 100%;
  width: 100%;
}

.user_info {
  word-break: break-all;
  margin-top: 3px;
}

.user_info > h4 {
  font-size: 18px;
}

.user_info > h3 {
  font-size: 16px;
  font-weight: 400;
  color: grey;
}
