* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Jost', sans-serif;
}

::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  scrollbar-width: none;
  background: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
