.pre_loader {
  --gap: 20px;
  --num-cols: 3;
  --row--height: 400px;

  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row--height);
  gap: var(--gap);
  height: 100%;
  overflow: hidden;
}

.pre_loader > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.skeleton {
  animation: skeleton-loading 0.6s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 95%);
  }
  100% {
    background-color: hsl(0, 0%, 85%);
  }
}

@media screen and (max-width: 1024px) {
  .pre_loader {
    --num-cols: 2;
    --row-height: 200px;
  }
}

@media screen and (max-width: 760px) {
  .pre_loader {
    --gap: 5px;
    --num-cols: 1;
    --row--height: 300px;
    margin-top: 0;
  }
}
