.collection_list {
  --columns: 3;
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  gap: 80px 40px;
}

.collection_list > a {
  text-decoration: none;
}

.collection_photos {
  display: flex;
  flex-direction: column;
  gap: 130px;
}

.collection_photos_wrap {
  position: relative;
}

.collection_photo_item {
  width: 85%;
  height: 200px;
  overflow: hidden;
  margin-left: 30px;
  animation: skeleton-loading 0.6s forwards infinite alternate;
}

.collection_photo_item:nth-child(1)::before {
  content: '';
  width: 85%;
  height: 100%;
  background: linear-gradient(0deg, #8787877d 75%, #ffffff);
  position: absolute;
  top: 0;
  left: 30px;
  opacity: 50%;
}

.collection_photo_item:nth-child(2) {
  width: 93%;
  position: absolute;
  top: 19px;
  left: -15px;
  overflow: hidden;
  animation: skeleton-loading 0.6s forwards infinite alternate;
}

.collection_photo_item:nth-child(2)::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 40%;
  background: linear-gradient(0deg, #8787877d 75%, #ffffff);
}

.collection_photo_item:nth-child(3) {
  width: 99%;
  height: 260px;
  position: absolute;
  top: 45px;
  left: -26px;
  overflow: hidden;
  animation: skeleton-loading 0.6s forwards infinite alternate;
}

.collection_photo_item:nth-child(3)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 50%;
  background: linear-gradient(0deg, #7373737d, #ffffff);
}

.collection_about {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
  color: black;
}

.collection_about > div:nth-child(1) {
  width: 120px;
  height: 23px;
  animation: skeleton-loading 0.6s forwards infinite alternate;
}

.collection_about > div:nth-child(2) {
  width: 200px;
  height: 20px;
  animation: skeleton-loading 0.6s forwards infinite alternate;
}

.tag_list {
  margin: 20px 0 0 10px;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 97%);
  }
  100% {
    background-color: hsl(0, 0%, 85%);
  }
}

@media screen and (max-width: 1000px) {
  .collection_list {
    --columns: 2;
  }
}

@media screen and (max-width: 760px) {
  .collection_list {
    --columns: 1;
    margin: 10px 10px 10px 8px;
  }
}
