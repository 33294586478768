.sub_header {
  display: flex;
  align-items: center;
}

/* TOPICS */
.topics_wrap {
  width: calc(100%);
  position: relative;
}

.topics {
  display: flex;
  gap: 30px;
  overflow: scroll;
  padding-left: 20px;
  align-items: center;
}

.topics::-webkit-scrollbar {
  display: none;
}

.topics > a {
  text-decoration: none;
}

.topic_item {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  white-space: nowrap;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 70px;
  border-bottom: 3px solid transparent;
  padding-top: 4px;
  color: rgb(93, 93, 93);
}

.active_topic {
  border-bottom: 3px solid rgb(25, 25, 25);
  color: rgb(25, 25, 25);
}

/* IMAGE VIEW MODE */
.view_mode {
  position: relative;
  display: flex;
  gap: 10px;
  padding-right: 20px;
  border-right: 0.5px solid grey;
  border: 1px solid #757575;
  border-radius: 10px;
  padding: 8px 8px 4px 8px;
}

.view_mode::before {
  content: 'testing';
  background: #787878;
  border-radius: 10px;
  padding: 4px 6px;
  position: absolute;
  top: 34px;
  left: -8px;
  color: white;
  font-size: 12px;
}

.view_mode:after {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: not-allowed;
}

.view_mode > button {
  background: none;
  border: none;
  cursor: pointer;
}

.view_mode > button > svg {
  width: 25px;
  height: 25px;
}

.view_mode > button > svg > path {
  strokewidth: 1.3px;
}

@media screen and (max-width: 760px) {
  .topics {
    gap: 20px;
    padding-left: 0;
  }

  .view_mode {
    display: none;
  }

  .topic_item {
    padding: 15px 5px 18px 5px;
    border: none;
    border-top: 3px solid transparent;
    height: 60px;
  }

  .active_topic {
    border: none;
    border-top: 3px solid rgb(25, 25, 25);
    color: black;
  }
}
