.main_block {
  height: 100%;
  display: flex;
}

.photo_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.photo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 155px);
}

.photo > img {
  min-height: min(100%, 50px);
  max-height: 100%;
  max-width: 100%;
  object-fit: scale-down;
}

.photo_actions {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
  padding: 10px 10px 0 0;
}

.photo_action_item {
  display: flex;
  gap: 6px;
  align-items: center;
  transition: all 0.3s ease;
  border: 1px solid #d7d7d7;
  border-radius: 50px;
  position: relative;
}

.photo_action_item > a {
  text-decoration: none;
}

.link_item {
  border: none;
  padding: 10px 18px 10px 20px;
  background: none;
  border-radius: 50px;
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}

.link_item > svg {
  width: 22px;
  height: 22px;
}

.link_item > h4 {
  font-weight: 400;
  color: #9b9b9b;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.link_item:hover {
  background: rgb(238, 238, 238);
  border-radius: 50px;
  transition: all 0.3s ease;
}

.link_item:hover > svg > path {
  stroke: #777777;
}

.link_item:hover > h4 {
  color: #777777;
}

.photo_action_item > h4 {
  font-weight: 400;
  color: #9b9b9b;
  font-size: 16px;
}

.photo_about {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 450px;
  height: calc(100vh - 100px);
  padding-top: 5px;
  padding-left: 20px;
  border-left: 1px solid #c3c3c3;
  overflow: scroll;
}

.user_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user_wrap > img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid rgb(193, 193, 193);
}

.user_name > h4 {
  font-size: 20px;
}

.user_name > h3 {
  font-weight: 400;
  font-size: 16px;
  color: #afafaf;
}

.photo_descr {
  word-break: break-word;
  font-weight: 400;
  font-size: 17px;
}

/* PHOTO STATS */
.photo_stats {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;
  border-top: 1px solid #c3c3c3;
}

.stat_item_wrap {
  width: 100%;
}

.stat_item {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #8d8d8d;
}

.stat_item > h4 {
  font-weight: 400;
  font-size: 17px;
}

.stat_item > svg {
  width: 20px;
  height: 20px;
}

.stat_count {
  font-size: 18px;
}

.photo_action_item[data-img-size]::before {
  content: attr(data-img-size);
  position: absolute;
  background: #ffffff;
  top: 0;
  left: -1px;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 50px;
  color: #767676;
  align-items: center;
  font-size: 17px;
  justify-content: center;
  transition: 0.3s;
  border: 1px solid #d7d7d7;
  opacity: 0;
}

.photo_action_item[data-img-size]:hover:before {
  opacity: 1;
  top: -53px;
  transition: 0.3s;
}

/* ABOUT PHOTO */
.photo_info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
  border-top: 1px solid #c3c3c3;
}

.info_item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.info_item_icon {
  display: flex;
  align-self: flex-start;
  padding-top: 1px;
}

.info_item_icon > svg {
  width: 20px;
  height: 20px;
}

.info_item > h4 {
  font-weight: 400;
  font-size: 16px;
}

/* PHOTO TAGS */
.photo_tags_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 20px;
  border-top: 1px solid #c3c3c3;
}

.tag_item {
  text-decoration: none;
  background: #e6e6e6;
  border-radius: 5px;
  color: #767676;
  padding: 6px 10px;
  font-size: 16px;
  cursor: pointer;
}

.tag_item:hover {
  background: #d7d7d7;
  color: #767676;
}

/* RELATED COLLECTIONS */
.related_collections_wrap {
  margin: 50px 20px 30px 20px;
}

.related_collections_wrap > h4 {
  font-size: 22px;
  margin-bottom: 30px;
  font-weight: 400;
}

/* CLOSE BUTTON */
.close_btn {
  background: none;
  border: none;
  position: fixed;
  top: 50px;
  right: 70px;
  display: flex;
}

.close_btn:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: -1;
  padding: 8px;
  top: -40%;
  left: -10px;
  border-radius: 30px 0 0 30px;
}

.close_btn > svg {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.close_btn > svg > path {
  stroke: rgb(193, 193, 193);
  strokewidth: 1.3px;
}
