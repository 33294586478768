.loader {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: solid 3px;
  border-color: #000000 #00000010 #00000010;
  position: relative;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
