.collection {
  width: 100%;
  height: 310px;
  position: relative;
}

.collection_bg {
  width: 100%;
  height: 100%;
  animation: skeleton-loading 1s linear infinite alternate;
}

.collection_about {
  position: absolute;
  top: 50px;
  left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
}

.collection_title {
  width: 220px;
  height: 45px;
  margin-bottom: 25px;
  animation: skeleton-loading-title 1s linear infinite alternate;
}

.collection_user {
  display: flex;
  align-items: center;
  gap: 8px;
}

.user_photo {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  animation: skeleton-loading-title 1s linear infinite alternate;
}

.user_name {
  width: 120px;
  height: 20px;
  animation: skeleton-loading-title 1s linear infinite alternate;
}

.collection_total {
  font-size: 18px;
  margin-left: 20px;
  margin-bottom: 8px;
}

.collection::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 45%, #fff 100%);
  width: 100%;
  height: 130%;
}

.collection_total_photos {
  position: absolute;
  bottom: -25px;
  left: 60px;
  width: 100px;
  height: 20px;
  animation: skeleton-loading-title 1s linear infinite alternate;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 95%);
  }
  100% {
    background-color: hsl(0, 0%, 85%);
  }
}

@keyframes skeleton-loading-title {
  0% {
    background-color: hsl(0, 0%, 85%);
  }
  100% {
    background-color: hsl(0, 0%, 75%);
  }
}

@media screen and (max-width: 760px) {
  .collection_about {
    top: 50px;
    left: 30px;
  }

  .collection_total_photos {
    left: 10px;
  }
}
