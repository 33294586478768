.search_bar {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px 14px;
  width: 100%;
  min-width: 350px;
  gap: 10px;
  cursor: text;
  background: #eee;
  transition: all 0.5s ease;
  transition: width 0.5s ease;
}

.search_bar_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
}

.search_bar > input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 18px;
  background: none;
  transition: width 0.5s ease;
}

@media screen and (max-width: 760px) {
  .search_bar {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 12px 6px 12px;
    width: 100%;
    min-width: auto;
    gap: 10px;
  }

  .search_btn {
    border-radius: 9px;
    font-size: 16px;
    padding: 0 10px;
    color: black;
    border: none;
    cursor: pointer;
  }
}
