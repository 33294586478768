.loader_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.loader_item {
  width: 80px;
  height: 35px;
  border-radius: 5px;
  animation: skeleton-loading 0.6s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 95%);
  }
  100% {
    background-color: hsl(0, 0%, 85%);
  }
}
