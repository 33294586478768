.search_page {
  margin: 20px 80px;
}

.search_about {
  display: flex;
  gap: 10px;
  overflow: scroll;
}

.search_short_info {
  display: flex;
  align-items: center;
  background: #e4e4e4;
  border-radius: 5px;
  color: #474747;
  font-size: 18px;
  margin-right: 10px;
  text-align: center;
  width: fit-content;
  white-space: nowrap;
  padding: 15px;
  gap: 2px 8px;
  flex-wrap: wrap;
}

.search_short_info > svg {
  width: 30px;
  height: 30px;
}

.search_short_info > svg > path {
  stroke: #474747;
  strokewidth: 1.4px;
}

.search_about > h2 {
  position: relative;
  display: inline-flex;
  text-transform: capitalize;
  font-size: 40px;
  margin-bottom: 30px;
  align-items: center;
}

@media screen and (max-width: 760px) {
  .search_about > h2 {
    font-size: 30px;
    padding: 10px 20px;
    margin-bottom: 20px;
  }

  .collection_list {
    margin: 10px 10px 10px 8px;
  }

  .user_list {
    margin: 10px 15px;
  }

  .search_about > h2:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #e4e4e4;
    border-radius: 0 5px 5px 0;
    z-index: -1;
  }

  .search_page {
    margin: 15px 5px;
  }
}
