.img_wrap {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scale_cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.scale_full {
  min-height: min(100%, 50px);
  max-height: 100%;
  max-width: 100%;
  object-fit: scale-down;
}
