.preloader_item {
  height: 70px;
  display: flex;
  align-items: center;
}

.topic_item {
  animation: skeleton-loading 0.6s linear infinite alternate;
  display: flex;
  border-radius: 20px;
  padding: 8px 15px;
  white-space: nowrap;
  color: transparent;
  margin-top: 3px;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 95%);
  }
  100% {
    background-color: hsl(0, 0%, 85%);
  }
}

@media screen and (max-width: 760px) {
  .preloader_item {
    height: 60px;
  }
}
