.photo_list {
  --gap: 20px;
  --num-cols: 3;
  --row--height: 400px;

  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);

  grid-auto-rows: var(--row--height);
  gap: var(--gap);
}

@media screen and (max-width: 1024px) {
  .photo_list {
    --num-cols: 2;
  }
}

@media screen and (max-width: 760px) {
  .photo_list {
    --gap: 10px;
    --num-cols: 1;
    --row--height: 300px;
  }
}

/* .photo_list > a div {
  margin-bottom: 20px;
} */

/* @media screen and (max-width: 1024px) {
  .photo_list {
    -webkit-column-count: 2;
    -webkit-column-gap: 0px;
    -moz-column-count: 2;
    -moz-column-gap: 0px;
    column-count: 2;
    column-gap: 20px;
  }
}

@media screen and (max-width: 420px) {
  .photo_list {
    -webkit-column-count: 2;
    -webkit-column-gap: 0px;
    -moz-column-count: 2;
    -moz-column-gap: 0px;
    column-count: 2;
    column-gap: 5px;
  }

  .photo_list > a > div {
    margin-bottom: 5px;
  }
} */
