.sub_header {
  display: flex;
  align-items: center;
  min-width: 200px;
}

/* TOPICS */
.topics_wrap {
  width: 100%;
  position: relative;
}

.topics {
  display: flex;
  gap: 30px;
  overflow: scroll;
  padding-left: 10px;
  align-items: center;
  list-style: none;
}

.left_smooth_block:before {
  content: '';
  top: 0;
  left: -2px;
  width: 160px;
  position: absolute;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  background: linear-gradient(270deg, #fff0 0, #fff 80%, #fff);
  transition: opacity 0.2s ease-in-out;
}

.active_left_block::before {
  opacity: 1;
  z-index: 10;
  transition: opacity 0.2s ease-in-out;
}

.right_smooth_block:after {
  content: '';
  top: 0;
  right: -2px;
  width: 160px;
  position: absolute;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  background: linear-gradient(90deg, #fff0 0, #fff 85%, #fff);
  transition: opacity 0.2s ease-in-out;
}

.active_right_block::after {
  opacity: 1;
  z-index: 10;
  transition: opacity 0.2s ease-in-out;
}

.topic_item > a {
  text-decoration: none;
  color: rgb(106, 106, 106);
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  white-space: nowrap;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  padding-top: 4px;
}

.topic_item:hover > a {
  color: rgb(25, 25, 25);
}

.active_topic > a {
  border-bottom: 3px solid rgb(25, 25, 25);
}

.active_topic > a {
  color: rgb(25, 25, 25);
}

/* ARROWS */
.topics > svg > path {
  stroke: #858585;
}

.topics > svg:first-child {
  position: absolute;
  z-index: 20;
  left: 5px;
}

.topics > svg:last-child {
  position: absolute;
  z-index: 20;
  right: -5px;
}

.topics::-webkit-scrollbar {
  display: none;
}

/* IMAGE VIEW MODE */
.view_mode {
  position: relative;
  display: flex;
  gap: 10px;
  padding-right: 20px;
  border-right: 0.5px solid grey;
  border: 1px solid #757575;
  border-radius: 10px;
  padding: 8px 8px 4px 8px;
}

.view_mode::before {
  content: 'testing';
  background: #787878;
  border-radius: 10px;
  padding: 4px 6px;
  position: absolute;
  top: 34px;
  left: -8px;
  color: white;
  font-size: 12px;
}

.view_mode:after {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: not-allowed;
}

.view_mode > button {
  background: none;
  border: none;
  cursor: pointer;
}

.view_mode > button > svg {
  width: 25px;
  height: 25px;
}

.view_mode > button > svg > path {
  strokewidth: 1.3px;
  stroke: #757575;
}

@media screen and (max-width: 760px) {
  .topics {
    gap: 20px;
    padding-left: 0;
  }

  .view_mode {
    display: none;
  }

  .topics_wrap {
    width: calc(100%);
  }

  .topic_prev:before {
    width: 60px;
  }

  .topic_next:after {
    width: 90px;
  }

  .topics > svg:first-child {
    left: -5px;
  }

  .topics > svg:last-child {
    right: -5px;
  }

  .topic_item > a {
    border: none;
    border-top: 3px solid transparent;
    padding: 0;
    padding-bottom: 4px;
    height: 55px;
  }

  .active_topic > a {
    border: none;
    border-top: 3px solid rgb(25, 25, 25);
  }
}
