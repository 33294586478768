.collection {
  width: 100%;
  height: 310px;
  position: relative;
}

.collection_bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collection_about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  position: absolute;
  top: 40px;
  left: 60px;
}

.collection_about > h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 20px;
}

.collection_user {
  display: flex;
  align-items: center;
  gap: 12px;
}

.collection_user > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  box-shadow: 0px 0px 10px #787878;
  border-radius: 50px;
}

.collection_user > h4 {
  font-size: 18px;
  font-weight: 500;
}

.collection_total {
  font-size: 18px;
  margin-bottom: 8px;
}

.collection::before {
  content: '';
  width: 100%;
  height: 130%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(211, 211, 211, 0.2) -90%, #fff 99%);
}

.photo_list {
  padding: 0 60px;
  margin-bottom: 50px;
  background: white;
}

@media screen and (max-width: 760px) {
  .collection_about {
    left: 30px;
  }

  .collection_about > h2 {
    font-size: 40px;
    word-break: keep-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .collection::before {
    background: linear-gradient(180deg, rgba(211, 211, 211, 0.2) -90%, #fff 99%);
  }

  .collection_total {
    margin-left: 10px;
  }

  .photo_list {
    margin: 0 5px;
    padding: 0;
  }
}
